import { useQuery } from "@obvio/app";

import type { Schema } from "@obvio/app";
import type { DeepPick } from "@obvio/utils";

export const ARTICLE_SELECT = {
  id: true,
  createdAt: true,
  slug: true,
  title: true,
  image: true,
  content: true,
  seoDescription: true,
} as const;

export type ARTICLE_DATA = DeepPick<Schema["Article"], typeof ARTICLE_SELECT>;

type query = typeof useQuery<
  "getLatestArticles",
  typeof ARTICLE_SELECT,
  { select: typeof ARTICLE_SELECT; args: { count: number } }
>;

export function useArticlesLatest(limit = 4): ReturnType<query> {
  return useQuery("getLatestArticles", {
    select: ARTICLE_SELECT,
    args: {
      count: limit,
    },
  });
}
