import { plainRichText } from "@obvio/app";

export const dateFormatter = new Intl.DateTimeFormat("en", {
  month: "short",
  year: "numeric",
  day: "2-digit",
});

// return string "n dni temu" if time is less than month, else return dateFormatter.format
export function formatDate(date: Date): string {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (days < 30) {
    return `${days} dni temu`;
  }

  return dateFormatter.format(date);
}

export function normalizeContent(content: any[]): string {
  const firstText = content.find(
    (block: unknown) => typeof block === "string"
  ) as string | undefined;
  return `${plainRichText(firstText ?? "").slice(0, 100)}...`;
}

export function timeToRead(content: any[]): number {
  const texts = content.filter(
    (block: unknown) => typeof block === "string"
  ) as string[];
  const words = texts.reduce((acc, text) => text.split(" ").length + acc, 0);

  const avgTime = 238; // 238 words per minute
  return Math.ceil(words / avgTime);
}
